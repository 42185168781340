import axios from 'axios'

export default {
  async index({ params }) {
    return new Promise((resolve, reject) => {
      axios
        .get('user_service_plan_item', {
          params: params
        })
        .then(res => {
          resolve(res.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  async remainCountDeduct(id, postData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`user_service_plan_item/${id}/remain_count_deduct`, postData)
        .then(res => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getContent(userServicePlanItem) {
    if (!userServicePlanItem.service_plan_item) {
      return null
    }
    const _servicePlanItem = userServicePlanItem.service_plan_item
    const _type = _servicePlanItem.type
    if (_type == 'custom-items') {
      const _item = _servicePlanItem.items.find(e => {
        return e.value == userServicePlanItem.content
      })
      if (_item) {
        return _item.text
      } else {
        return null
      }
    } else if (_type == 'boolean') {
      if (userServicePlanItem.content) {
        return 'Y'
      } else {
        return 'N'
      }
    } else {
      return userServicePlanItem.content
    }
  }
}