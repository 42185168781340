<template>
  <WsMain>
    <WsReadSection
      :id="_id"
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :leftFields="leftFields"
      :rightFields="rightFields"
      :updatable="false"
      :deletable="false"
    ></WsReadSection>
    <WsCard>
      <WsTable
        v-if="userServicePlanItems.length"
        :items="_tableItems"
        :headers="tableHeaders"
        :actions="tableActions"
        @oncheck="$_onTableCheck($event)"
      ></WsTable>
    </WsCard>
    <WsPopup
      :title="$t('使用扣除')"
      ref="usingCount"
    >
      <template v-slot:content>
        <WsStateForm
          :fields="usingCountFields"
          v-model="state.usingCount"
        ></WsStateForm>
      </template>
      <template v-slot:rightActions>
        <WsBtn
          @click="$_onUsingCountCancel()"
          :minWidth="100"
          color="textSecondary"
          outlined
        >{{$t('cancel')}}</WsBtn>
        <WsBtn
          @click="$_onUsingCountSubmit()"
          :minWidth="100"
          color="primary"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsPopup>
  </WsMain>
</template>

<script>
import model from "@/__vue2stone_cms/models/user_service_plan";
import S_API_UserServicePlanItem from "@/__vue2stone_cms/service/api/v1/user_service_plan_item";
export default {
  data: () => ({
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    leftFields: ["service_plan", "user"],
    rightFields: ["created_at"],
    userServicePlanItems: [],
    tableHeaders: [
      {
        text: "服務項目",
        value: "plan_service_item",
      },
      {
        text: "內容",
        value: "content",
      },
      {
        text: "總次數",
        value: "total_count",
      },
      {
        text: "剩餘次數",
        value: "remain_count",
      },
    ],
    tableActions: [
      {
        icon: "icon-md-library-books",
        getTo(item) {
          return `/user_service_plan_item/${item.id}`;
        },
        displayCheck(item) {
          if (item.total_count) {
            return true;
          } else {
            return false;
          }
        },
      },
      {
        icon: "icon-ws-outline-reduce",
        emit: "oncheck",
        displayCheck(item) {
          if (item.total_count && item.remain_count) {
            return true;
          } else {
            return false;
          }
        },
      },
    ],
    usingCountFields: {
      count: {
        type: "number",
        label: "扣除數量",
      },
      remark: {
        type: "textarea",
        label: "備註",
      },
    },
    state: {
      usingCount: {
        count: 1,
        remark: "",
      },
    },
    focus: {
      usingCount: {
        item: null,
        itemIndex: null,
      },
    },
  }),

  methods: {
    async $_onUsingCountCancel() {
      this.$refs.usingCount.close();
      this.state.usingCount.count = 1;
      this.state.usingCount.remark = "";
      this.focus.usingCount.item = null;
      this.focus.usingCount.itemIndex = null;
    },
    async $_onUsingCountSubmit() {
      await S_API_UserServicePlanItem.remainCountDeduct(
        this.focus.usingCount.item.id,
        {
          ...this.state.usingCount,
        }
      );
      this.userServicePlanItems[this.focus.usingCount.itemIndex].remain_count -=
        this.state.usingCount.count;
      this.$refs.usingCount.close();
    },
    async $_onTableCheck($event) {
      this.focus.usingCount.item = $event.item;
      this.focus.usingCount.itemIndex = $event.itemIndex;
      this.$refs.usingCount.open();
    },
    async $_fetchUserServicePlanItems() {
      this.userServicePlanItems = await S_API_UserServicePlanItem.index({
        params: {
          user_service_plan: this._id,
        },
      });
    },
  },

  computed: {
    _tableItems() {
      const _tableItems = [];
      this.userServicePlanItems.forEach((userServicePlanItem) => {
        const _name = userServicePlanItem.service_plan_item
          ? userServicePlanItem.service_plan_item.name
          : null;
        const _total_count = userServicePlanItem.total_count
          ? userServicePlanItem.total_count
          : null;
        const _remain_count = userServicePlanItem.remain_count
          ? userServicePlanItem.remain_count
          : null;
        const _content =
          S_API_UserServicePlanItem.getContent(userServicePlanItem);
        _tableItems.push({
          id: userServicePlanItem.id,
          plan_service_item: _name,
          total_count: _total_count,
          remain_count: _remain_count,
          content: _content,
        });
      });
      return _tableItems;
    },
    _id() {
      return this.$route.params.id;
    },
  },

  mounted() {
    this.$_fetchUserServicePlanItems();
  },
};
</script>

<style>
</style>